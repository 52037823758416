import app from "firebase/app";
import { firebaseConfig } from "./../versionInfo";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.db = app.firestore();
    this.db.enablePersistence().catch(function(err) {
      console.log("error enabling persistence: " + err);
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log(
          "failed precondition setting enablePersistance, multiple tabs open"
        );
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log("the current browser doesnt support persistanceEnabled");
      }
    });

    this.storage = app.storage();
  }
  db = () => this.db;
  auth = () => this.auth;
}

export default Firebase;
