import React from "react";

export default function UploadProgress(props) {
  console.log("percent being passed to progress bar is : " + props.percent);
  return (
    <div className="progress">
      <div
        className={
          props.percent === 100 ? "progress-bar complete" : "progress-bar"
        }
        role="progressbar"
        style={{ width: props.percent + "%" }}
        aria-valuenow={props.percent}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
}
