import React from "react";

export default function Confirmation(props) {
  return (
    <div className="card confirmation">
      <p className="lead">{props.question}</p>
      <div className="confirmationControls">
        <button
          className="controlButton btn btn-secondary confirmationButton"
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(true);
          }}
        >
          Yes
        </button>
        <button
          className="controlButton btn btn-secondary confirmationButton"
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(false);
          }}
        >
          No
        </button>
        <button
          className="controlButton btn btn-secondary confirmationButton"
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
